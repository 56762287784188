import React, { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const AccountDeletionPage = () => {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    // const checkUser = async () => {
    //   const {
    //     data: { user },
    //   } = await supabase.auth.getUser();
    //   setUser(user);
    //   if (user) {
    //     setEmail(user.email);
    //   }
    // };
    // checkUser();
  }, []);

  const pageStyle = {
    backgroundColor: "#1E1E1E",
    color: "#FFA500",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    boxSizing: "border-box",
  };

  const contentStyle = {
    maxWidth: "400px",
    width: "100%",
    textAlign: "center",
  };

  const inputStyle = {
    backgroundColor: "#333",
    color: "#FFA500",
    border: "1px solid #FFA500",
    padding: "10px",
    margin: "10px 0",
    fontSize: "16px",
    width: "100%",
  };

  const buttonStyle = {
    backgroundColor: "#FFA500",
    color: "#1E1E1E",
    border: "none",
    padding: "10px 20px",
    margin: "10px 0",
    cursor: "pointer",
    fontSize: "16px",
    width: "100%",
  };

  const errorStyle = {
    color: "red",
    marginTop: "10px",
  };
  const sendDeletionEmail = async () => {
    setError("");
    try {
      const { error } = await supabase.auth.signInWithOtp({
        email: email,
        options: {
          emailRedirectTo: `${window.location.origin}/redirect`,
        },
      });
      if (error) throw error;
      setStep(1);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div style={pageStyle}>
      <div style={contentStyle}>
        <h1 style={{ fontSize: "24px", marginBottom: "20px" }}>DISTANCE</h1>

        {step === 0 && (
          <>
            <h2>계정 삭제</h2>
            <p>계정을 삭제하시려면 이메일 인증이 필요합니다.</p>
            {user ? (
              <p>현재 로그인된 이메일: {email}</p>
            ) : (
              <input
                type="email"
                placeholder="이메일 입력"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={inputStyle}
                required
              />
            )}
            <button style={buttonStyle} onClick={sendDeletionEmail}>
              삭제 인증 이메일 보내기
            </button>
            {error && <p style={errorStyle}>{error}</p>}
          </>
        )}

        {step === 1 && (
          <>
            <h2>이메일 확인</h2>
            <p>
              {email}로 계정 삭제 인증 이메일을 보냈습니다. 이메일의 링크를
              클릭하여 계정 삭제를 완료해주세요.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default AccountDeletionPage;
