import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import AccountDeletionPage from "./page/accountDeletionPage";
import ConfirmDeletionPage from "./page/confirmDeletionPage";
import RedirectHandler from "./page/redirectHandlerPage";
import PrivacyPolicyPage from "./page/privacyPolicyPage";

const HomePage = () => {
  const pageStyle = {
    backgroundColor: "#1E1E1E",
    color: "#FFA500",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial, sans-serif",
  };

  const linkStyle = {
    color: "#FFA500",
    textDecoration: "none",
    border: "1px solid #FFA500",
    padding: "10px 20px",
    margin: "10px",
    borderRadius: "5px",
  };

  return (
    <div style={pageStyle}>
      <h1>DISTANCE</h1>
      <nav>
        <Link to="/delete-account" style={linkStyle}>
          계정 삭제
        </Link>
      </nav>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/delete-account" element={<AccountDeletionPage />} />
        <Route path="/confirm-deletion" element={<ConfirmDeletionPage />} />
        <Route path="/redirect" element={<RedirectHandler />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
      </Routes>
    </Router>
  );
};

export default App;
