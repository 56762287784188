import React, { useState, useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
import { useNavigate, useLocation } from "react-router-dom";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const ConfirmDeletionPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleDeletion = async () => {
      try {
        // URL에서 토큰 추출
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("token");
        const type = searchParams.get("type");

        if (!token || type !== "recovery") {
          throw new Error("유효하지 않은 토큰입니다.");
        }

        // 토큰으로 세션 생성
        const { data, error } = await supabase.auth.verifyOtp({ token, type });

        if (error) {
          throw error;
        }

        if (!data.user) {
          throw new Error("사용자 인증에 실패했습니다.");
        }

        // Edge Function을 호출하여 계정 삭제
        const { error: deletionError } = await supabase.functions.invoke(
          "deleteUser",
          {
            body: JSON.stringify({ userId: data.user.id }),
          }
        );

        if (deletionError) {
          throw deletionError;
        }

        setSuccess(true);
        // 세션 종료
        await supabase.auth.signOut();
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    handleDeletion();
  }, [location]);

  const pageStyle = {
    backgroundColor: "#1E1E1E",
    color: "#FFA500",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    boxSizing: "border-box",
  };

  const contentStyle = {
    maxWidth: "400px",
    width: "100%",
    textAlign: "center",
  };

  const buttonStyle = {
    backgroundColor: "#FFA500",
    color: "#1E1E1E",
    border: "none",
    padding: "10px 20px",
    margin: "10px 0",
    cursor: "pointer",
    fontSize: "16px",
  };

  if (loading) {
    return (
      <div style={pageStyle}>
        <div style={contentStyle}>
          <h1>DISTANCE</h1>
          <p>계정 삭제 처리 중...</p>
        </div>
      </div>
    );
  }

  return (
    <div style={pageStyle}>
      <div style={contentStyle}>
        <h1>DISTANCE</h1>
        {success ? (
          <>
            <h2>계정 삭제 완료</h2>
            <p>당신의 계정이 성공적으로 삭제되었습니다.</p>
            <p>DISTANCE를 이용해 주셔서 감사합니다.</p>
            <button style={buttonStyle} onClick={() => navigate("/")}>
              홈으로 돌아가기
            </button>
          </>
        ) : (
          <>
            <h2>계정 삭제 실패</h2>
            <p>{error}</p>
            <button style={buttonStyle} onClick={() => navigate("/")}>
              홈으로 돌아가기
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmDeletionPage;
