import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicyPage = () => {
  const pageStyle = {
    backgroundColor: "#1E1E1E",
    color: "#ffffff",
    minHeight: "100vh",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  };

  const contentStyle = {
    maxWidth: "800px",
    margin: "0 auto",
    lineHeight: "1.6",
  };

  const headingStyle = {
    color: "#FFFFFF",
    marginTop: "30px",
    marginBottom: "15px",
  };

  const linkStyle = {
    color: "#FFA500",
    textDecoration: "underline",
  };

  return (
    <div style={pageStyle}>
      <div style={contentStyle}>
        <h1 style={{ ...headingStyle, fontSize: "24px", textAlign: "center" }}>
          DISTANCE
        </h1>
        <h2 style={{ ...headingStyle, fontSize: "20px", textAlign: "center" }}>
          개인정보처리방침
        </h2>

        <p>
          Distance(사업자명 : 클레드(CLED)) 은(는) 「개인정보 보호법」 제30조에
          따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고
          원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을
          수립·공개합니다.
        </p>
        <p>○ 이 개인정보처리방침은 2024년 9월 1부터 적용됩니다.</p>

        <h3 style={headingStyle}>제1조(개인정보의 처리 목적)</h3>
        <p>
          Distance은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고
          있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용
          목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의
          동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </p>
        <p>
          ① 모바일 애플리케이션 회원가입 및 관리
          <br />
          회원 가입의사 확인 목적으로 개인정보를 처리합니다.
        </p>

        <h3 style={headingStyle}>제2조(개인정보의 처리 및 보유 기간)</h3>
        <p>
          ① Distance은(는) 법령에 따른 개인정보 보유·이용기간 또는
          정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간
          내에서 개인정보를 처리·보유합니다.
        </p>
        <p>② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
        <p>
          1. 모바일 애플리케이션 회원가입 및 관리 : 애플리케이션 탈퇴 시까지
          <br />
          다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료 시까지
          <br />
          1) 관계 법령 위반에 따른 수사, 조사 등이 진행 중인 경우는 해당 수사,
          조사 종료 시까지
        </p>

        <h3 style={headingStyle}>제3조(처리하는 개인정보의 항목)</h3>
        <p>① Distance은(는) 다음의 개인정보 항목을 처리하고 있습니다.</p>
        <p>
          1 &lt; 애플리케이션 회원가입 및 이용 &gt;
          <br />
          필수항목 : 이름(아이디), E-mail
          <br />
          선택항목 : 소개, 상태, 프로필 사진
        </p>

        <h3 style={headingStyle}>제4조(개인정보의 제3자 제공에 관한 사항)</h3>
        <p>
          ① Distance은(는) 개인정보를 제1조(개인정보의 처리 목적)에서 명시한
          범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등
          「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를
          제3자에게 제공합니다.
        </p>

        <h3 style={headingStyle}>제5조(개인정보처리의 위탁에 관한 사항)</h3>
        <p>
          ① Distance은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이
          개인정보 처리업무를 위탁하고 있습니다.
        </p>
        <p>
          1. &lt; 내용 &gt;
          <br />
          위탁받는 자 (수탁자) : Amazon Web Service
          <br />
          위탁하는 업무의 내용 : AWS를 통한 Storage 서비스(프로필 사진)
          <br />
          위탁기간 : 회원 탈퇴 시까지
        </p>
        <p>
          ② 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보
          처리방침을 통하여 공개하도록 하겠습니다.
        </p>

        <h3 style={headingStyle}>제6조(개인정보의 파기절차 및 파기방법)</h3>
        <p>
          ① Distance은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
          불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
        </p>
        <p>
          ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
          달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
          하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
          보관장소를 달리하여 보존합니다.
        </p>
        <p>③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.</p>
        <p>
          1. 파기절차
          <br />
          Distance은(는) 파기 사유가 발생한 개인정보를 선정하고, Distance의
          개인정보 보호책임자에 의해 직접 또는 승인을 받아 개인정보를
          파기합니다.
        </p>
        <p>
          2. 파기방법
          <br />
          데이터베이스 데이터 완전 삭제를 통해 전자적 파일 형태의 정보가 재생될
          수 없습니다.
        </p>

        <h3 style={headingStyle}>
          제7조(정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)
        </h3>
        <p>
          ① 정보주체는 Distance에 대해 언제든지 개인정보 열람·정정·삭제·처리정지
          요구 등의 권리를 행사할 수 있습니다.
        </p>
        <p>
          ② 제1항에 따른 권리 행사는 Distance에 대해 「개인정보 보호법」 시행령
          제41조제1항에 따라 서면, 전자우편, 등을 통하여 하실 수 있으며
          Distance은(는) 이에 대해 지체 없이 조치하겠습니다.
        </p>
        <p>
          ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
          대리인을 통하여 하실 수 있습니다. 이 경우 "개인정보 처리 방법에 관한
          고시(제2020-7호)" 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
        </p>
        <p>
          ④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항,
          제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
        </p>
        <p>
          ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
          대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
        </p>
        <p>
          ⑥ Distance은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
          처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
          대리인인지를 확인합니다.
        </p>

        <h3 style={headingStyle}>
          제8조(개인정보의 안전성 확보조치에 관한 사항)
        </h3>
        <p>
          Distance은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
          있습니다.
        </p>
        <p>
          1. 내부관리계획의 수립 및 시행
          <br />
          개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
          있습니다.
        </p>
        <p>
          2. 개인정보에 대한 접근 제한
          <br />
          개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
          부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
          조치를 하고 있습니다.
        </p>

        <h3 style={headingStyle}>
          제9조(행태정보의 수집·이용·제공 및 거부 등에 관한 사항)
        </h3>
        <p>
          Distance은(는) 온라인 맞춤형 광고 등을 위한 행태정보를
          수집·이용·제공하지 않습니다.
        </p>

        <h3 style={headingStyle}>제10조 (개인정보 보호책임자에 관한 사항)</h3>
        <p>
          ① Distance은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고,
          개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여
          아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
        </p>
        <div style={{ marginLeft: "20px" }}>
          <p>▶ 개인정보 보호책임자</p>
          <p style={{ marginLeft: "20px" }}>
            성명 : 조연서
            <br />
            직급 : 개인사업자
            <br />
            연락처 : 24cled@gmail.com
          </p>
        </div>
        <p>
          ② 정보주체께서는 Distance의 서비스(또는 사업)을 이용하시면서 발생한
          모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
          개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. Distance은(는)
          정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
        </p>

        <h3 style={headingStyle}>
          제11조(정보주체의 권익침해에 대한 구제방법)
        </h3>
        <p>
          정보주체는 개인정보침해로 인한 구제를 받기 위하여
          개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
          분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의
          신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
        </p>
        <ol>
          <li>
            개인정보분쟁조정위원회 : (국번없이) 1833-6972 (
            <a href="http://www.kopico.go.kr" style={linkStyle}>
              www.kopico.go.kr
            </a>
            )
          </li>
          <li>
            개인정보침해신고센터 : (국번없이) 118 (
            <a href="http://privacy.kisa.or.kr" style={linkStyle}>
              privacy.kisa.or.kr
            </a>
            )
          </li>
          <li>
            대검찰청 : (국번없이) 1301 (
            <a href="http://www.spo.go.kr" style={linkStyle}>
              www.spo.go.kr
            </a>
            )
          </li>
          <li>
            경찰청 : (국번없이) 182 (
            <a href="http://ecrm.cyber.go.kr" style={linkStyle}>
              ecrm.cyber.go.kr
            </a>
            )
          </li>
        </ol>
        <p>
          「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의
          정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여
          공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를
          받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.
        </p>
        <p>
          ※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(
          <a href="http://www.simpan.go.kr" style={linkStyle}>
            www.simpan.go.kr
          </a>
          ) 홈페이지를 참고하시기 바랍니다.
        </p>

        <Link
          to="/"
          style={{
            ...linkStyle,
            display: "block",
            marginTop: "30px",
            textAlign: "center",
          }}
        >
          홈으로 돌아가기
        </Link>
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
